import React, { useEffect, useState } from "react";
import {
  getCurrentPhoneNumber,
  getCurrentPhoneNumberFormatted,
  defaultPhoneNumber,
  defaultPhoneNumberFormatted,
} from "../thirdParty/leadspedia";

export default function thanks() {
  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber);
  const [phoneNumberFormatted, setPhoneNumberFormatted] = useState(
    defaultPhoneNumberFormatted
  );

  useEffect(() => {
    setPhoneNumber(getCurrentPhoneNumber());
    setPhoneNumberFormatted(getCurrentPhoneNumberFormatted());
  }, []);

  return (
    <>
      <div className="thankyou row">
        <div className="col-12 query">
          <h2 className="thankyou-heading text-primary">
            Thank you for submitting!
          </h2>
        </div>
        <p className="thankyou-subtext col-12">
          A representative will be reaching out to you shortly for next steps.
          If you&apos;d like to connect immediately, please call{" "}
          <a href={`tel:${phoneNumber}`} className="text-primary text-nowrap">
            {phoneNumberFormatted}
          </a>
        </p>
      </div>
    </>
  );
}
