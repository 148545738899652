/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";

import * as CONST from "../../CONSTANTS";
import * as ECONST from "./ECONSTANTS";

function ConnectStreamsModal({ fData }) {
  const _formData = fData[CONST.DATA];
  const phone = _formData[CONST.CN_PHONE];
  const leadID = _formData[CONST.CN_SEND_LEAD];

  useEffect(() => {
    const renderScript = document.createElement("script");
    renderScript.innerHTML =
      "window.g3cm = window.g3cm || function () { (g3cm.q = g3cm.q || []).push(arguments) }; g3cm('loadjquery', 'true'); g3cm('phonenumber', '" +
      phone +
      "'); g3cm('send', 'init');";

    document.body.appendChild(renderScript);

    const script = document.createElement("script");
    script.src = "https://api.connectstreams.com/js/connectme-v3.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div
      className="g3cm_holder"
      data-module={ECONST.CONNECTSTREAMS_MODAL_MODULEID}
      data-showoninit="true"
      data-calloninit="false"
      data-conversionid={leadID}
      data-destination=""
      data-destinationext=""
      data-followup=""
      style={{ display: "none" }}
    >
      Thank you for your inquiry. A representative will be in contact with you
      shortly.
    </div>
  );
}

export { ConnectStreamsModal };
