/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from "react";

import * as CONST from "../CONSTANTS";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FireEvent from "../components/fireEvent";
import CakePixel from "/src/components/thirdParty/cake";

import Thanks from "../components/wizard/thanks";
import { ConnectStreamsModal } from "../components/thirdParty/connectStreams";
import { initMediaalphaPlacement } from "../components/thirdParty/mediaalpha";
import { isBrowser } from "../HELPERS";

export default function ThankyouPage({ location }) {
  const [reqid, setReqid] = useState("");

  const formData =
    location && location.state && location.state["formData"]
      ? location.state["formData"]
      : null;

  const _formData = formData ? formData[CONST.DATA] : null;
  const { leadID, leadPrice } = _formData
    ? _formData[CONST.CN_SEND_LEAD][CONST.DATA]
    : { leadID: null, leadPrice: null };

  useEffect(() => {
    if (isBrowser()) {
      setReqid(window.reqid ? window.reqid : "");
    }

    initMediaalphaPlacement(formData, "thankyou");
  });

  return (
    <Layout layoutType={CONST.LAYOUT_WIZARD_PAGE}>
      <Seo title="Thank You" />

      {reqid && leadID && (
        <CakePixel reqid={reqid} leadID={leadID} leadPrice={leadPrice} />
      )}

      {_formData && (
        <>
          {" "}
          <ConnectStreamsModal fData={formData} />
          <Thanks fData={formData} />
        </>
      )}

      <FireEvent
        eventName="formStepChange"
        eventData={{ stepName: CONST.CN_THANKS }}
      />
      <FireEvent
        eventName="lead_generated"
      />
    </Layout>
  );
}
