import React from "react";

const isLocalhost = () => {
  return location.hostname === "localhost" || location.hostname === "127.0.0.1";
};

/**
 * A component for pushing events into the GTM datalayer. Uses component did mount to ensure a window dom element is available.
 * Further work is needed on this component to make it more powerful
 */
export default class DataLayer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <></>;
  }
  componentDidMount() {
    const { eventName, eventData } = this.props;
    if (isLocalhost()) {
      return;
    }

    {
      window.dataLayer.push({
        event: eventName,
        eventData: eventData,
        // pageType: pageType,
        // destination: destination
      });
    }
  }
}
