import React from "react";
import * as ECONST from "./ECONSTANTS";

export default function CakePixel({ leadID, reqid, leadPrice }) {
  let postbackURL = `https://lmxsecure.com/p.ashx?${ECONST.CAKE_URL_IDENTIFIER}&t=${leadID}&r=${reqid}`;
  postbackURL = leadPrice ? postbackURL + `&p=${leadPrice}` : postbackURL;

  return (
    <iframe
      src={postbackURL}
      height="1"
      width="1"
      frameBorder="0"
      style={{ position: "absolute" }}
    ></iframe>
  );
}
